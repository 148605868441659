<div *ngIf="!callcenterShared.activeCallerSession && this.callcenterShared.showDialer">
    <outbound-call (triggerScrollToTop)="scrollToTopFromChild()"></outbound-call  >
</div>
        <active-call-info (pickCall) = "onPickCall($event)" (rejectCall) = "onRejectCall($event)"
        (endCall) = "onEndCall($event)" (holdCall) = "onHoldCall($event)" (muteCall) = "onMuteCall($event)" 
        [callerSession]="callcenterShared.activeCallerSession"></active-call-info>

<ng-container *ngIf="!this.callcenterShared.showDialer || callcenterShared.activeCallerSession">
    <call-list (pickCall) = "onPickCall($event)"  (rejectCall) = "onRejectCall($event)" (holdCall) = "onHoldCall($event)"
    (endCall) = "onEndCall($event)"></call-list>
</ng-container>










import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { AppConstants } from 'app/core/constants/app.constants';
import { AppEventType, StatusValueEnum } from 'app/core/enums/common.enum';
import { FireMessagingService } from 'app/core/services/fire-messaging.service';
import { User } from 'app/core/user/user.types';
import { UserStatusUpdateRequest } from 'app/modules/ring2voice/models/call-center.models';
import { CallCenterSharedService } from 'app/modules/ring2voice/services/call-center-shared.service';
import { CallCenterService } from 'app/modules/ring2voice/services/call-center.service';
import { CallNotificationService } from 'app/modules/ring2voice/services/call-notification.service';
import { SipConnectionService } from 'app/modules/ring2voice/services/sip-connection.service';
import { SipInboundFunctionService } from 'app/modules/ring2voice/services/sip-inbound-function.service';
import { SipOutboundFunctionService } from 'app/modules/ring2voice/services/sip-outbound-function.service';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { RegistererState } from 'sip.js';
import { SubSink } from 'subsink';
import {MatSliderModule} from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { IndexDbHttpService } from 'app/shared/services/index-db-http.service';
import { AppSharedConstants } from 'app/core/constants/app-shared.constants';
import { CommonService } from 'app/core/services/common.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule,FormsModule,
    NgClass, MatDividerModule, ShortNamePipe, MatRippleModule, TranslocoModule, MatSliderModule],
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  
  user: User;

  public tempName = "Bentley Ericson";

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private subscription?: Subscription

  public status: string = StatusValueEnum.OFFLINE;

  public selectedAudioInput: string;

  public selectedOutputAudio: string;

  private subs: SubSink = new SubSink();

  private connectingSIP$ = new BehaviorSubject<boolean>(false);

  statusInt: number;
  //private notificationForCall: boolean = false;

  private tenantId:any = localStorage.getItem(AppConstants.TENANT_ID)

  private userRole:number = Number(localStorage.getItem(AppConstants.USER_ROLE))

  sip_name: string;

  isStatusChanging: boolean = false;
  
  first_time: boolean = true;

  locDBName: string = AppSharedConstants.INDEXDB_NAME
  userName: string;
  


  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private callCenterService: CallCenterService,
    private sipConnectionService: SipConnectionService,
    private sipInboundFunctionService: SipInboundFunctionService,
    public callcenterSharedService: CallCenterSharedService,
    public callNotificationService: CallNotificationService,
    public fireMsgService: FireMessagingService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private sipOutboundFunctionService: SipOutboundFunctionService,
    private _authService: AuthService,
    private router: Router,
    private indexDBHttpService : IndexDbHttpService,
    private _commonService: CommonService

  ) {


  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.callcenterSharedService.sipDetails = null
    console.log('sipdetoninit',this.callcenterSharedService.sipDetails)
    this.user = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
    this.userName = `${this.user.firstname} ${this.user.lastname}`;
    if (this.user) {
      this.user["avatar"] = "assets/images/avatars/brian-hughes.jpg";
    }

    if (!this.callCenterService.isCustomer) {
      this.getAgentDetailsApi();
      this.callcenterSharedService.agentDetails$.subscribe(() => {
        this.getAgentDetailsApi();
      });
    }
    this._commonService.on(AppEventType.UserUpdate).subscribe((event)=>{
      this.user.firstname = event.payload.first_name;
      this.user.lastname = event.payload.last_name;
      this.user.roleid = event.payload.role;
      this.user.rolename = event.payload.role_name;
      localStorage.setItem(AppConstants.USER_AUTH, JSON.stringify(this.user))
      localStorage.setItem(AppConstants.USER_ROLE, this.user.roleid.toString())
      this.userName = `${this.user.firstname} ${this.user.lastname}` ;
      this.userRole = this.user.roleid;
      if (!this.callCenterService.isCustomer)
        this.getAgentDetailsApi();
      this.cdr.detectChanges();
    })
  }

  ngAfterViewInit(): void {
    const audioelement = document.getElementById('audio') as HTMLAudioElement;
    this.sipInboundFunctionService.remoteAudio = audioelement
    this.sipOutboundFunctionService.mediaElement = audioelement
  }

  getAgentDetailsApi() {
    this.subscription = this.callCenterService.getAgentConnectionDetById().subscribe(res => {

      if(res.http_status == 200)
        {
          console.log('sip_details',res)
          this.callcenterSharedService.sipDetails = res.data.result;
          this.sip_name = res.data?.result?.agent_name;
          localStorage.setItem(AppConstants.SIP_URI, res.data?.result?.sip_uri)
          localStorage.setItem(AppConstants.SEND_AS_NUMBER, res.data?.result?.send_as)
          if(!this.callcenterSharedService.isNotificationEnabled){
            this.status = StatusValueEnum.OFFLINE
          }
          else if(this.callcenterSharedService.sipDetails)
          {
            if (this.callcenterSharedService.sipDetails.status == StatusValueEnum.ON_CALL || this.callcenterSharedService.sipDetails.status == StatusValueEnum.ONLINE) {
              this.statusEvtChange(StatusValueEnum.ONLINE);
              this.status = StatusValueEnum.ONLINE
              }
            else if (this.callcenterSharedService.sipDetails.status == StatusValueEnum.BUSY) {
                this.status = StatusValueEnum.BUSY
              }
            else {
                this.status = StatusValueEnum.OFFLINE
              }
          }   
          this.setIntStatus();
          this.cdr.markForCheck();
        }
    })
  }

  onStatusChange(agentStatus: string = null, isBusy:boolean = false) {

    if(this.callcenterSharedService.sipDetails || this.callcenterSharedService.sipDetails?.sip_id != null)
      {
        this.changeAgentStatus(agentStatus,isBusy)
    
        this.callcenterSharedService.sipDetails.status = agentStatus;
      }
      else
      {
        this.statusInt = 3;
        this.status = "Offline"
        this.toastr.error('User SIP details not found')
        this.cdr.markForCheck()
      }



  }
  async statusEvtChange(status: string, isBusy: boolean = false) {

    switch (status) {
      case StatusValueEnum.ONLINE:
       // if (!this.callcenterSharedService.isSIPRegistered$.getValue()) {

          setTimeout(() => {

            // navigator.mediaDevices.getUserMedia({ 'audio': { deviceId: this.selectedAudioInput ? { exact: this.selectedAudioInput } : undefined } })
              //.then(stream => {
                try {
                  this.sipConnectionService.initialiseConnection().then((state) => {

                    
                    if (state === RegistererState.Registered) {

                      localStorage.setItem('isOnCall', 'false');
                      this.callcenterSharedService.isSIPRegistered$.next(true)
                      this.callcenterSharedService.sipDetails.status = StatusValueEnum.ONLINE;
                      if(isBusy){
                        this.status = StatusValueEnum.BUSY;
                        this.statusInt = 2;
                      }else{
                        this.status = StatusValueEnum.ONLINE;
                        this.statusInt = 1;
                      }
                      this.cdr.markForCheck();
                      this.connectingSIP$.next(false);

                      // console.log("*************** SIP Successfully registered ***************");
                      console.warn("*************** SIP Successfully registered ***************");
                      this.sipInboundFunctionService.inviteCall()
                    
                      if (this.callcenterSharedService.notificationForCall) {
                        console.warn('on initial load connect')
                        this.callNotificationService.connectCallOnInitialLoad()
                        this.callcenterSharedService.notificationForCall = false
                      }
                      if(this.first_time){
                        if(this.callcenterSharedService.activeCallerSession){
                          this.callcenterSharedService.setAutoAcceptableCallIds(this.callcenterSharedService.activeCallerSession.callDetails.call_id)
                          this.sipInboundFunctionService.is_override = true;
                          this.sipInboundFunctionService.oncallConnect(this.callcenterSharedService.activeCallerSession)
                        }
                        this.first_time = false;
                      }
                    } else if (state === RegistererState.Unregistered) {

                      this.connectingSIP$.next(false);
                      this.callcenterSharedService.isSIPRegistered$.next(false)
                      this.callcenterSharedService.sipDetails.status = StatusValueEnum.ONLINE;
                      if(isBusy){
                        this.status = StatusValueEnum.BUSY;
                        this.statusInt = 2;
                      }else{
                        this.status = StatusValueEnum.ONLINE;
                        this.statusInt = 1;
                      }
                      this.cdr.markForCheck();
                      console.warn('>>>>>>> SIP Successfully unregistered! <<<<<<<<<');

                    } else if (state === RegistererState.Terminated) {
                      console.warn('>>>>>>> SIP Registration terminated <<<<<<<<<<');

                      this.connectingSIP$.next(false);

                      this.callcenterSharedService.isSIPRegistered$.next(false)
                      this.callcenterSharedService.sipDetails.status = StatusValueEnum.OFFLINE;
                      this.status = StatusValueEnum.OFFLINE
                      this.statusInt = 3;
                      this.cdr.markForCheck();
                    } else {
                      console.log('else part');

                    }
                  })
                }
                catch (e) {
                  if (this.callcenterSharedService.sipDetails.status == 'Online' || this.callcenterSharedService.sipDetails.status == 'Busy') {
                    this.statusEvtChange('Offline')
                  }

                  this.toastr.error('Failed to initialize sip connection');
                }
              
              // })
              // .catch(err => {
              //   this.statusEvtChange('Offline')
              //   this.toastr.error('Check microphone permission and try again.');
              // });

          }, 1300);


       // }
        break;

      case StatusValueEnum.BUSY:
        // only in Smart Sip Groups
        if (!this.callcenterSharedService.isSIPRegistered$.getValue()) {
          this.statusEvtChange('Online', true)
        }else{
          this.status = StatusValueEnum.BUSY;
        }
        break;

      case StatusValueEnum.OFFLINE:

        if (!this.callcenterSharedService.connection) {
          this.callcenterSharedService.isSIPRegistered$.next(false)
          this.statusInt = 3;
          setTimeout(() => {
            this.status = StatusValueEnum.OFFLINE
          }, 1200);
          this.cdr.markForCheck();

        }
        else {
          this.callcenterSharedService.connection.stop();
          this.callcenterSharedService.connection = null;
          this.statusInt = 3;
          setTimeout(() => {
            this.status = StatusValueEnum.OFFLINE
          }, 1200);
          this.cdr.markForCheck();
        }
        break;
    }
    

    // if (updateStatus) {
    //   this.changeAgentStatusApi(status);
    // }


  }

  changeAgentStatus(status,isBusy:boolean = false) {
    this.isStatusChanging = true;
    let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    payload.customer.push(this.tenantId);
    payload.status = status;
    payload.email = this.callCenterService.userEmail
    payload.role = this.userRole

    this.subs.sink = this.callCenterService.updateUserStatus(payload).subscribe({
      next:(responds) => {
        if (responds.http_status == 200) {
          
          this.statusEvtChange(status,isBusy)
          this.callcenterSharedService.sipDetails.status = status;
          this.cdr.markForCheck()
    
        }
        else
        {
          this.toastr.error(responds.message[0])
        }
      },
      error: (error)=>{

      },
      complete: ()=> {
        let time;
        if(status==StatusValueEnum.BUSY && !this.callcenterSharedService.isSIPRegistered$.getValue()){
          time = 3000;
        }else if(status==StatusValueEnum.BUSY && this.callcenterSharedService.isSIPRegistered$.getValue()){
          time = 500;
        }else if(status==StatusValueEnum.OFFLINE){
          time = 2000;
        }else{
          time = 3000;
        }
        setTimeout(() => {
          this.isStatusChanging = false;
          this.cdr.markForCheck();
        },time);//time for connection start/stop
        
      },})

  }

  onSliderChange(event: any){
    if(!this.callcenterSharedService.isNotificationEnabled){
      this.statusInt = 3;
      this.status = StatusValueEnum.OFFLINE;
      this.cdr.markForCheck();
      this.toastr.error(`Apologies, but you cannot set your status to ‘Online’ or ‘Direct calls’ because notifications 
        are not enabled in your browser, and it’s required to receive calls. Please enable them and try again.`, `Notification is not enabled!`,
        {closeButton: true, disableTimeOut: true, tapToDismiss: false});
      
    }
    else if(this.callcenterSharedService.sipDetails || this.callcenterSharedService.sipDetails?.sip_id != null)
      {
        this.statusInt = parseInt(event.target.value)
        if(this.statusInt == 1)
          this.onStatusChange("Online");
        else if(this.statusInt == 2)
          this.onStatusChange("Busy",true);
        else
          this.onStatusChange("Offline");
      }
    else
      {
        this.statusInt = 3;
        this.status = "Offline"
        this.toastr.error('User SIP details not found')
        this.cdr.markForCheck()
      }

  }

  setIntStatus(){
    if(this.status == StatusValueEnum.ONLINE)
      this.statusInt = 1;
    else if(this.status == StatusValueEnum.BUSY)
      this.statusInt = 2;
    else
      this.statusInt = 3;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }



  /**
   * Sign out
   */
  signOut(): void {
    let device_id = localStorage.getItem(AppConstants.DEVICE_ID);
    let user_id = parseInt(localStorage.getItem(AppConstants.USER_ID));
    
    this._authService.signOut(user_id, device_id).subscribe({
      next: (response) => {
        if(response.http_status==200){
          this.afterSignOut();
          this._router.navigate(['/sign-out']);
        }else{
          this.toastr.error("Error during sign-out");
        }
        
      },
      error: (error) => {
        console.error('Error during sign-out:', error);
        this.toastr.error("Internal server error");
      }
  });
  }

  afterSignOut(){
    this.statusEvtChange(StatusValueEnum.OFFLINE);
    this.callcenterSharedService.ringAudio.pause();
    this.fireMsgService.pushNotification = new BehaviorSubject(null);
    this.callcenterSharedService.callQ = [];
    this.callcenterSharedService.popupOpenStatus.next(false);
    this.callcenterSharedService.lastDialledNumber = null;

    this.toastr.success("Signed out successfully");
    localStorage.clear();
    this.indexDBHttpService.clearAllTables(this.locDBName)
  }

  myProfile(){
    let user_id = parseInt(localStorage.getItem(AppConstants.USER_ID));
    this.router.navigate(['org/users',user_id, 'edit']);
    this.menuTrigger.closeMenu();
  }
  
}

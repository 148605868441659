import { Directive, ElementRef, HostListener } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Directive({
  selector: '[PhoneNumberFormat]',
  standalone: true
})
export class PhoneNumberFormatDirective {

  private previousValue: string = '';

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const rawValue = input.value.replace(/\D+/g, ''); // Remove all non-digit characters

    if (rawValue.length > 6) {
      this.formatPhoneNumber(rawValue);
    } else {
      input.value = rawValue;
    }
  }

  @HostListener('blur')
  onBlur() {
    const input = this.el.nativeElement as HTMLInputElement;
    const rawValue = input.value.replace(/\D+/g, ''); // Remove all non-digit characters

    if (rawValue.length > 6) {
      this.formatPhoneNumber(rawValue);
    } else {
      input.value = rawValue;
    }
  }

  private formatPhoneNumber(value: string): void {
    let formattedValue = value;

    if (value.length > 6) {
      formattedValue = new AsYouType('US').input(value);
      // formattedValue = this.maskPipe.transform(value,'(000) 000-000000000');// value.replace(/(\d{4})(\d{1,3})?(\d{1,4})?/, '$1-$2-$3').replace(/-$/, '');
    }

    this.el.nativeElement.value = formattedValue;
    this.previousValue = formattedValue;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { KeyPadComponent } from '../key-pad/key-pad.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'active-call-info',
  standalone: true,
  imports: [CommonModule,PhoneNumberPipe,
    TranslocoModule, KeyPadComponent, ShortNamePipe],
  templateUrl: './active-call-info.component.html',
  styleUrl: './active-call-info.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('in', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-20px)'
        }),
        animate('0.5s ease-out')
      ]),
      transition('* => void', [
        animate('0.5s ease-in', style({
          opacity: 0,
          transform: 'translateY(-20px)'
        }))
      ])
    ])
  ]
})
export class ActiveCallInfoComponent {
    
  @Output() isClosed = new EventEmitter<boolean>();

  @Input() callerSession;

  @Input() audioInputSource;

  @Input() videoSource;

  @Input() show = false;

  @Output() movetoAcceptedCallsQ = new EventEmitter<any>();

  @Output() moveToAutoAccept = new EventEmitter<string>();

  @Output() clearEndedWhileonHoldCall: EventEmitter<boolean> = new EventEmitter<boolean>();

  public declineTone = new Audio();

  @Output() pickCall = new EventEmitter<any>

  @Output() rejectCall = new EventEmitter<any>

  @Output() endCall = new EventEmitter<any>

  @Output() holdCall = new EventEmitter<any>

  @Output() muteCall = new EventEmitter<any>

  public showKeypad : boolean = false

  constructor(private _toaster: ToastrService) {

  }
  ngOnInit(): void {

  }
  /**
   * 
   * mute ongoing call
   */

  onMuteCall() {

    
    this.muteCall.emit(this.callerSession)
  }

  /**
   * hold and unhold
   * create new call with music on hold
   * reconnect with new call on unhold
   */ 

  public onHoldCall() {

        this.holdCall.emit(this.callerSession)
      
  }

  /**
   * pickup incoming call
   */ 
  onPickkCall() {

    this.pickCall.emit(this.callerSession)


  }

  /**
   * 
   * reject incoming call
   */

  onRejectCall() {

    this.rejectCall.emit(this.callerSession)
  }
/**
 * hangup ongoing call
 */
  onEndCall() {
    
    this.endCall.emit(this.callerSession)
   
  }
  formatTime(seconds: number): string {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return mins+'m:'+secs+'s'
  }

  // private pad(value: number): string {
  //   return value < 10 ? '0' + value : value.toString();
  // }
  record()
  {
    this._toaster.info('Call recording is not supported yet');
  }
  transfer()
  {
    this._toaster.info('Call tranfer is not supported yet');
  }

  onShowKeypad()
  {
    this.showKeypad = !this.showKeypad
  }

  showMoreInfo()
  {
    alert('Coming soon')
  }

  addContact()
  {
    alert('Coming soon')
  }
  onCloseKeypad()
  {
    this.showKeypad = false
  }
  isNullorEmpty(value: string | null): boolean {
    return value === null || value === "" || value === undefined;
  }

}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { SipInboundFunctionService } from '../../services/sip-inbound-function.service';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { MatIconModule } from '@angular/material/icon';
import { SubSink } from 'subsink';
import { FireMessagingService } from 'app/core/services/fire-messaging.service';
import { CallNotificationService } from '../../services/call-notification.service';
import { SipOutboundFunctionService } from '../../services/sip-outbound-function.service';
import { SipConnectionService } from '../../services/sip-connection.service';
import { RegistererState } from 'sip.js';
import { ToastrService } from 'ngx-toastr';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { forEach } from 'lodash';
import _ from 'lodash';


@Component({
  selector: 'active-call-badge',
  standalone: true,
  imports: [CommonModule, ShortNamePipe, MatIconModule, PhoneNumberPipe],
  templateUrl: './active-call-badge.component.html',
  styleUrl: './active-call-badge.component.scss'
})


export class ActiveCallBadgeComponent implements OnInit {

  public declineTone = new Audio();

  private subs: SubSink = new SubSink();

  constructor(public inboundFnService: SipInboundFunctionService,
    public callcenterShared: CallCenterSharedService,
    public sipInboundFnService: SipInboundFunctionService,
    public fireMsgService: FireMessagingService,
    public callNotificationService: CallNotificationService,
    private sipconnectionFn : SipConnectionService,
    private sipOutboundService : SipOutboundFunctionService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void { 
    this.setCallInfo(); 
    this.subs.sink = this.fireMsgService.pushNotification.subscribe((val) => {
      console.log("pushNotification", val)
      if (val?.data) {
       if (val.data.call_id?.length > 0 && val.data.type == 'CALL') {

          console.log('notificationAction', val.data.notificationAction)
          if (val.data?.action == "start_ring") {

            // const isSipDisconnected: boolean = !this.callcenterShared.connection ||
            // this.callcenterShared.connection?.state != 'Started'

            console.warn('sip connection state on start ring',this.sipconnectionFn.isConnected)

            if(!this.sipconnectionFn.isConnected)
              {
                try {
                      this.sipconnectionFn.initialiseConnection().then((state) => {
            
                      if (state === RegistererState.Registered) {
                    
                        console.warn('registered from incoming push')
        
                      }
                      else
                      {
                        this.toastr.error('failed to connecting sip.')
                      }
                      })    
                    }
                    catch (e) {
                      this.toastr.error('Failed to initialize sip connection');
                    }
              }
     
            console.log('callnotifypush', val.data)
            const notificationAction = val.data.notificationAction? val.data.notificationAction : 'push'
            val.data.isInitialLoad = val.data?.isInitialLoad?val.data?.isInitialLoad : false

            if((notificationAction == 'push' && val.data.isInitialLoad == false) ||  (val.data.isInitialLoad == true))
              {
                if(!this.callcenterShared.callQ.find(x => x.callDetails.call_id == val.data.call_id))
                  {
                    this.pushtoIncomingCallQ(val,notificationAction)
                    console.warn('sip connection state', this.callcenterShared.connection?.state)
                    this.callNotificationService.callNotificationData.next(val.data)
                    this.callcenterShared.popupOpenStatus.next(true) 
                  }  
              }
            if(notificationAction == 'connect' && val.data.isInitialLoad == false)
              {
                this.callcenterShared.stopRing()
                const callerSession = this.callcenterShared.initialCallQ.find(x => x.callDetails.call_id == val.data.call_id)
                if(callerSession)
                  {
                    // callerSession.session.state = 'connect'
                    // this.callcenterShared.updateCallQ(callerSession,'inbound')
                    this.sipInboundFnService.oncallConnect(callerSession)
                  }
                 
              }  
              else if(notificationAction == 'connect' && val.data.isInitialLoad == true)
                {
                  this.callcenterShared.notificationForCall = true
                }  
              if(notificationAction == 'reject')  
                {
                  console.warn('call rejected from notification')
                 
                  if(!this.callcenterShared.initialCallQ)
                    {
                      this.sipInboundFnService.callDeclineTone()
                      this.callcenterShared.stopRing()        
                    }
                 
                  const callerSession = this.callcenterShared.initialCallQ.find(x => x.callDetails.call_id == val.data.call_id)
                  if(callerSession)
                    {
                      this.callcenterShared.removeFromCallQ(val.data.call_id)
                    }

                }
            }
            if(val.data?.action == "stop ring")
              {
                
                if(!this.callcenterShared.verifyCallIdAutoAccept(val.data.call_id))//to verify call attended not by this agent
                  {          
                    this.callcenterShared.removeFromCallQ(val.data.call_id)   
                    console.warn('call removed at stop ring')  
                  } 
                else if (this.callcenterShared.holdCallQ.find(x => x.callDetails.call_id == val.data.call_id)){
                  this.callcenterShared.removeFromCallQ(val.data.call_id)   
                  console.warn('hold call removed at stop ring')  
                }
                else
                  {
                    console.log('stopring')
                    this.callcenterShared.stopRing()
                  }     
              }
            if(val.data?.action == "outbound call answered")
              {
                const callerSession = this.callcenterShared.callQ.find(x => x.customValues.callType == 'outbound' && !x.customValues.isCallOnHold)
                if(callerSession)
                  {
                    if(callerSession.callDetails.parent_call_id == null)
                      {
                        callerSession.callDetails.parent_call_id = val.data.call_id
                        this.sipOutboundService.getCurrentCallId(callerSession,true,false,true)
                      }   
                  }
              }

            if(val.data?.action == "outbound call completed") 
              {
         
                const hold_callerSession = this.callcenterShared.holdCallQ.find(x => x.callDetails.parent_call_id == val.data.call_id)
                if(hold_callerSession)
                  {
                   this.callcenterShared.removeByParentCallId(hold_callerSession.callDetails.parent_call_id)
                  }
              }   
            
          }
        
         }
    })
  }
  
  setCallInfo(){
    let callq = JSON.parse(localStorage.getItem("CallInfo")) || [];
    let inbound_callq = callq.filter((call)=> call.customValues.callType == "inbound")
    let oubound_callq = callq.filter((call)=> call.customValues.callType != "inbound")
    if(inbound_callq?.length > 0 ) {
      this.setCallTimeOnReload(inbound_callq);
      if(this.callcenterShared.initialCallQ.length > 0 || this.callcenterShared.activeCallerSession || this.callcenterShared.holdCallQ.length>0){
        this.callcenterShared.popupOpenStatus.next(true)
        if(this.callcenterShared.initialCallQ.length > 0)
          this.callcenterShared.ring();
        if(this.activeCallerSession)
          this.activeCallerSession.session.state = null;
      }
    }
    if(oubound_callq?.length > 0){
      this.toastr.warning("The outgoing call has been ended...")
      oubound_callq.map((call)=> {this.callcenterShared.updateCallInfoInLocalStorage(call, true)})
    }
    this.callcenterShared._callQ.subscribe((data) =>{
      console.log("dattattatatatata", data)
      this.updateSessionInLocalStorage(data)
    }) 
  }

  setCallTimeOnReload(callQ){
    let current_time = new Date().getTime()
    callQ.forEach((_call)=>{
      _call.customValues.call_answered = false;
      let checkout_diff = current_time - _call.customValues.callStartTime
      _call.customValues.checkoutTime =Math.ceil( checkout_diff / 1000 )
      if(_call.customValues.notification_established == false && _call.customValues.callType == "inbound"){
        _call.checkouttimer = null
        this.callcenterShared.checkCallTimeout(_call);
      }
      if(_call.customValues.callConnectTime != null){
        let elapsed_diff = current_time - _call.customValues.callConnectTime
        _call.customValues.elapsedTime = Math.ceil(elapsed_diff / 1000)
        if(_call.customValues.isCallOnHold){
          _call.timer = null
          this.callcenterShared.startCallTimer(_call);
        }
      }
    })
    this.callcenterShared.callQ = callQ
  }

  updateSessionInLocalStorage(data){
    let temp_array = []
    data.forEach((call)=>{
      let local_session = _.merge({}, call)
      local_session["session"] = {"state":local_session?.session?.state};
      temp_array.push(local_session)
    })
    localStorage.setItem("CallInfo", JSON.stringify(temp_array))
  }
  
  pushtoIncomingCallQ(val:any,status)
  {
    this.sipInboundFnService.pushtoCallQ({callDetails:val.data,session:{state:status}})
    if(status != 'connect')
      {
        this.callcenterShared.ring()
      }

  }


  openCallCenter() 
  { 
    this.callcenterShared.popupOpenStatus.next(true)
  }

  openDialer() 
  {
    const isOpen = this.callcenterShared.popupOpenStatus.getValue()
    this.callcenterShared.showDialer = !isOpen // to identify user clicked dialer or not
    this.callcenterShared.popupOpenStatus.next(!isOpen)
  }

  public get activeCallerSession() {
    return this.callcenterShared.activeCallerSession // this.sipInboundFnService.selectedCaller
  }

  /**
 * 
 * mute ongoing call
 */

  muteCall() {

    this.activeCallerSession.customValues.isMuted = !this.activeCallerSession.customValues.isMuted
    this.callcenterShared.muteCall(this.activeCallerSession)
  }

  endCall() {
    //if (session.state == 'Established') {
    this.activeCallerSession.session.bye().then(() => {
      this.callDeclineTone();
    }).catch(() => {
      alert('Failed to end call')
    })
    // }
  }

  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play();
  }


  formatTime(seconds: number): string {
    // const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return seconds < 60 ? secs + 's' : mins + 'm:' + secs + 's';
  }

  isNullorEmpty(value: string | null): boolean {
    return value === null || value === "" || value === undefined;
  }


  // private pad(value: number): string {
  //   return value < 10 ? '0' + value : value.toString();
  // }

}

<div class="flex justify-center">

      <div class="bg-emerald-500 rounded-3xl px-1 py-1 call-info-container cursor-pointer" 
       [ngClass]="this.activeCallerSession  ? 'expand' : 'shrink'">
    
         <div class="px-1 py-1 flex cursor-pointer"   *ngIf="!this.activeCallerSession" (click) = "openDialer()"  >
            <mat-icon aria-hidden="true" [svgIcon]="'dialpad'" 
            class="text-white"></mat-icon>
         </div>
            <div class="call-info-container"  *ngIf="this.activeCallerSession" (click) = "openCallCenter()">

            <div class="flex flex-nowrap items-center pl-1 pr-1">
              
                  <div class="h-8 w-8 rounded-full mr-1 p-1 flex justify-center items-center text-lg bg-white border-black">
                
                     @if(activeCallerSession?.customValues?.callType == 'inbound'){

                        @if(activeCallerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(activeCallerSession?.callDetails?.agent_name)){
                                UN
                            }
                            @else{
                                {{activeCallerSession.callDetails?.agent_name | shortName}}
                            }
                        }
                        @if(!activeCallerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(activeCallerSession.callDetails?.company_users_name)){
                                UN
                            }
                            @else{
                                {{activeCallerSession.callDetails?.company_users_name | shortName}}
                            }
                        } 
                    }
                    @else if(activeCallerSession?.customValues?.callType == 'outbound'){
                        @if(activeCallerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(activeCallerSession.callDetails?.to_sip_user?.name)){
                                UN
                            }
                            @else{
                                {{activeCallerSession.callDetails?.to_sip_user?.name | shortName}}
                            }
                        }
                        @if(!activeCallerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(activeCallerSession.callDetails?.contact_name)){
                                UN
                            }
                            @else{
                                {{activeCallerSession.callDetails?.contact_name | shortName}}
                            }
                        }
                       
                    }
       

                </div>
                <div class="text-white ml-.5 mr-3">

                   <p class="text-base leading-4 font-medium">

                     @if(activeCallerSession?.customValues?.isAgentCall)
                     {
                        @if (activeCallerSession.customValues.callType == 'inbound') {
                           {{isNullorEmpty(activeCallerSession?.callDetails?.agent_name)? 'Unknown': 
                           activeCallerSession?.callDetails?.agent_name+' ('+activeCallerSession?.callDetails?.agent_sub_org_name+')'}}
                           
                        }
                        @else {
                           {{isNullorEmpty(activeCallerSession.callDetails?.to_sip_user?.name)? 'Unknown': 
                          activeCallerSession.callDetails?.to_sip_user?.name+' ('+activeCallerSession?.callDetails?.to_sip_user?.suborg__name+')'}}
                        }
                     }
                     
                     @if(!activeCallerSession?.customValues?.isAgentCall)
                     
                     {
                        @if (activeCallerSession.customValues.callType == 'inbound') {
                           {{isNullorEmpty(activeCallerSession?.callDetails?.company_users_name)? 'Unknown': 
                           activeCallerSession?.callDetails?.company_users_name}}
                           
                        }
                        @else {
                           {{isNullorEmpty(activeCallerSession?.callDetails?.contact_name)? 'Unknown': 
                           activeCallerSession?.callDetails?.contact_name}} 
                        }
                     }
                     <span class="timer">   
                    <span [hidden]="!activeCallerSession?.customValues.elapsedTime" class="ml-1">{{formatTime(this.activeCallerSession?.customValues.elapsedTime) }}</span> </span></p>
                   <p class="text-sm font-medium" *ngIf="activeCallerSession.customValues.callType == 'inbound'">
                     @if(activeCallerSession.customValues.isAgentCall){
                        {{activeCallerSession?.callDetails?.from_number.split('@')[0]}}
                     }
                     @else{
                        {{activeCallerSession?.callDetails?.from_number.length > 6 ? (activeCallerSession?.callDetails?.from_number | phoneNumber) : activeCallerSession?.callDetails?.from_number}}
                     }
                   </p>
                    <p class="text-sm font-medium" *ngIf="activeCallerSession.customValues.callType == 'outbound'">{{!this.activeCallerSession?.customValues?.isAgentCall ? (this.activeCallerSession?.callDetails?.users_number | phoneNumber)
                     : this.activeCallerSession?.customValues?.callTo}}</p>
                </div>
            
                <div matRipple class="mx-1 h-8 w-8 rounded-full cursor-pointer bg-red-500 p-.5 shadow-lg call-end-active" >
                   <img src="../../../../../assets/icons/call-center/call-end.svg" class="cursor-pointer" alt="FAQ" (click)="endCall(activeCallerSession)">
                </div>
                <!-- [ngClass]="activeCallerSession.session?.state == 'Established' ? 'call-end-active' : 'call-end-disabled'" -->
            
                <div *ngIf="!activeCallerSession.customValues.isMuted" matRipple class="mr-0.5 h-8 w-8 rounded-full cursor-pointer bg-gray-700 p-1 shadow-lg" 
                [ngClass]="{'action-disable': activeCallerSession.session?.state != 'Established'}">
                   <img src="../../../../../assets/icons/call-center/mute-white.svg" class="bg-white-700 cursor-pointer" alt="FAQ" (click) = "muteCall()">
                </div>
                <div *ngIf="activeCallerSession.customValues.isMuted" matRipple class="mr-0.5 h-8 w-8 rounded-full cursor-pointer bg-gray-700 p-1 shadow-lg" 
                [ngClass]="{'mute-action': activeCallerSession.customValues.isMuted}" (click) = "muteCall()">
                  <img src="../../../../../assets/icons/call-center/mute.svg" class="bg-white-700 cursor-pointer" alt="FAQ">
               </div>
             
            </div>

         </div>
          


</div>


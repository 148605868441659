import { CustomerLocation, CustomerRep, CustomerRoles, QuickContactRequest, TenantCustomer } from './../models/tenant-customer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerContact } from '../models/tenant-customer';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { BaseService } from 'app/core/services/base.service';
import { DataFilterResponse } from 'app/core/models/grid-filter.models';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { SfHttpService } from 'app/shared/services/sf-http.service';
import { IndexDbHttpService } from 'app/shared/services/index-db-http.service';
@Injectable({
  providedIn: 'root'
})
export class TenantCustomerService extends BaseService {

  public externalFilter: any[] = [];

  public storeData$ = new Subject<any>();

  private storeName: string = 'customer_contact'

  constructor(private http: HttpClient,private sfHttpService: SfHttpService,private indexDbService: IndexDbHttpService) { super() }

  getAllTenantCustomers(suborg_id = this.suborgId) {
    return this.http.get<TenantCustomer[]>(`${this.baseURL}/tenant_customer/?suborg=${suborg_id}`);
  }

  getAll(option: any, offset: number = 0, pageSize: number = 50, suborg_id: number = this.suborgId) {
    let url = `${this.baseURL}/tenant_customer/filter/?limit=${pageSize}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<TenantCustomer[]>>(url, option).pipe(
      catchError(() => of())
    );
  }

  fetchCustomeContactsFiltered_LocalDB(option: any = {}, limit: number = 0, offset: number = 0, 
    suborg_id: number = this.suborgId):any {

      const apiCallurl = `${this.baseURL}/filter_customer_contacts/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
      return this.sfHttpService.post<CustomerContact>(apiCallurl,option,this.storeName,limit,offset,this.storeData$,null,500).subscribe(res=>{});
  }
  getCustomerContactById(id: any) {
    return this.http.get<DataFilterResponse<CustomerContact>>(`${this.baseURL}/tenant_customer/contacts/${id}`);
  }

  createCustomerContact(model: CustomerContact) {
    return this.http.post<DataFilterResponse<CustomerContact>>(`${this.baseURL}/tenant_customer/contacts/`, model);
  }

  updateCustomerContact(id: string, model: CustomerContact) {
    return this.http.put<DataFilterResponse<CustomerContact>>(`${this.baseURL}/tenant_customer/contacts/${id}`, model);
  }
}






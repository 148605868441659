<div class="fixed panel-container z-49 bottom-0">
    <div class=" flex flex-col w-full h-full transition-transform duration-400 ease-drawer bg-card"
           [ngClass]="{'-translate-x-full shadow': opened, 'translate-x-0': !opened}">
           <div class="flex h-full">
                <div #scrollableContainer class="content-container" [ngClass]="type == 'contact' ? 'contact-container' : ''">
                    <ng-container *ngIf="type == 'contact'">
                       <company-user-add [id]="id" (onDelete)="onDeleteContact($event)"></company-user-add>
                    </ng-container>
                </div>
            </div>
     </div> 
</div>      
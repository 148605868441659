<div class="custom-dialog">
    <h2 mat-dialog-title class="pl-0 pr-0">Crop Image</h2>
    <mat-dialog-content class="pl-0 pr-0">
    <div class="cropper-container">
        <image-cropper *ngIf="show" class="p-0"
            [imageBase64]="data.image" 
            [maintainAspectRatio]="true" 
            [aspectRatio]="4 / 3" 
            format="png" 
            output="base64"
            (imageCropped)="imageCropped($event)"
            [transform]="transform"
            (wheel)="onWheel($event)"
        ></image-cropper>
    </div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex justify-end p-0">
      <button mat-stroked-button (click)="onNoClick()" class=" canel-button">Cancel</button>
      <button mat-flat-button (click)="onCrop()" class="crop-button ml-4" >Crop</button>
    </mat-dialog-actions>
</div>
import { Directive, HostListener, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ChatService } from "app/modules/omni-chat/services/chat.service";

@Directive({
    selector: '[InitiateChat]',
    standalone: true
  })
  export class InitiateChatDirective {
  
    @Input({required:true}) toNumber: any;
  
    constructor(private _chatSertvice: ChatService,
        private router: Router
    ) { }
  
    /**
     * @Input callType: The type of call to initiate (e.g., 'contact' or 'sip').
     * @Input fromNumber: The source of the call.
     * @Input toNumber: The destination of the call.
     * @Input agentsipuri: The SIP URI of the agent initiating the call.
     * @Input callto_agent_id (optional): The ID of the agent to call (if callType=='sip).
     */
  
    @HostListener('click')
    onClick() {
        this._chatSertvice.toNumber = this.toNumber;
        this.router.navigate(['omni-chat']);
    }
  
  }
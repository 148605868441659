import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppEventType } from 'app/core/enums/common.enum';
import { CommonService } from 'app/core/services/common.service';
import { DidsService } from '../../services/dids.service';
import { DataFilterRequest } from 'app/core/models/grid-filter.models';
import { SubSink } from 'subsink';
import { DIDNumber } from '../../models/dids.models';
import { AppConstants } from 'app/core/constants/app.constants';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'outgoing-number-select',
  standalone: true,
  imports: [CommonModule,  FormsModule, NgSelectModule, PhoneNumberPipe, NgxSkeletonLoaderModule],
  templateUrl: './outgoing-number-select.component.html',
  styleUrl: './outgoing-number-select.component.scss'
})
export class OutgoingNumberSelectComponent {
  selectedItem: any;
  private searchTerm$ = new Subject<string>();
  subs: SubSink = new SubSink();
  phone_numbers: DIDNumber[];
  isDropdownOpen: boolean;
  is_loaded: boolean = true;

  constructor(private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private didservice: DidsService
  ){}

  ngOnInit(){
    if(!this.didservice.current_send_as_number){
      this.didservice.current_send_as_number = this.removeCountryCode(localStorage.getItem(AppConstants.SEND_AS_NUMBER))
    }
    this.selectedItem = {"did": this.didservice.current_send_as_number}
    // this.getPhoneNumbers();
    this.searchTerm$
      .pipe(
        debounceTime(300), // Wait for 300ms pause in events
        distinctUntilChanged(), // Only emit when the current value is different than the last
      )
      .subscribe(term => {
        this.getPhoneNumbers(term)
      });
  }

  ngAfterViewInit(){
    this.subs.sink = this.commonService.on(AppEventType.SuborgSwitch).subscribe(event => {
      this.getPhoneNumbers();
    });
  }

  getPhoneNumbers(term?: any) {
    this.is_loaded= false;
    let option: DataFilterRequest = {
        sort: [{ colname: 'name', direction: 'asc' }],
        filters: []
    };
    if (term?.toLowerCase()) {
      option.filters.push(
        {
          "conditions":[
            { colname: 'did', condition: 'contains', value: term.toLowerCase(), operator: 'AND' }
          ], 
          "operator":"AND"
        });
    }
    this.subs.add(this.didservice.getAll(option, 0, 50).subscribe((resp)=>{
      this.phone_numbers =  resp.data?.result;
      this.is_loaded= true;
      this.cdr.detectChanges();
    }));
  }

  removeCountryCode(phoneNumber: string): string {
    if (phoneNumber.startsWith('+1')) {
        return phoneNumber.slice(2);
    }
    return phoneNumber;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.sid === o2.sid : o1 === o2;
  }

  selectItem(item: DIDNumber) {
    if(!(item instanceof Event)){
      this.selectedItem = item;
      this.didservice.current_send_as_number = this.removeCountryCode(this.selectedItem.did)
    }
  }

  onSearchTermChange(event: Event) {
    const inputElement = event.target as HTMLInputElement; // Cast to HTMLInputElement
    this.searchTerm$.next(inputElement.value); // Emit the new search term
  }

  closeDropdown(ngSelect: any) {
    ngSelect.close(); 
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.searchTerm$.unsubscribe(); // Clean up subscriptions on destroy
  }

}

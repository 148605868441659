import { Injectable } from '@angular/core';
import { Referral, RegistererState, Session, SessionState, Web } from 'sip.js';
import { BehaviorSubject, Subject, Subscription, timeout } from 'rxjs';
import { UserStatusUpdateRequest, callDetailsResponse } from '../models/call-center.models';
import { CallCenterService } from './call-center.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CallCenterSharedService } from './call-center-shared.service';
import { StatusValueEnum } from 'app/core/enums/common.enum';
import { CallNotificationService } from './call-notification.service';
import { SipConnectionService } from './sip-connection.service';
import { error } from 'console';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SipInboundFunctionService {

  public connection: any

  public totalCallCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public declineTone = new Audio();

  public acceptTone = new Audio();

  public callLogOpen: boolean = true;

  public subs: Subscription = new Subscription();

  public iscallcenterEnabled: boolean = true;

  public block_id: number;

  public remoteAudio = new Audio();

  public audioInputSource: string;

  public videoInputSource: any;

  public is_override : boolean = false;

  constructor(
    private callCenterService: CallCenterService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private callcenterSharedService: CallCenterSharedService,
    private callNotifyService: CallNotificationService,
    private sipConnectionService:SipConnectionService
  ) { }

  inviteCall() {
    this.connection = this.callcenterSharedService.connection
    let tis = this;
 
    this.callcenterSharedService.connection.delegate = {
 
    onInvite(invitation: any): void {


      if(invitation.request.headers['X-Signalwire-Callsid'])
        {
          const callid = invitation.request.headers['X-Signalwire-Callsid'][0].raw

          if (invitation.request.headers['X-Call-Status'] &&
            invitation.request.headers['X-Call-Status'][0].raw == 'unholded' &&
            tis.callcenterSharedService.verifyCallIdAutoAccept(callid)) {
              //for Unhold feature
            let callerSession = tis.callQ.filter(x => x.callDetails.call_id == callid)[0]
            callerSession.session = invitation
            callerSession.session.accept();
           
          
            tis.callcenterSharedService.stopCallTimer(callerSession)
            tis.listenToSessionStatusChanges(callerSession)
        }
        
        } 
      }
    };

  }

  listenToSessionStatusChanges(callerSession: any) {
  
   // this.callNotifyService.checkAndclearNotifyOnIncoming(callerSession.callDetails.call_id)
    let tis = this; 
    // Handle incoming session state changes.
    const invitation = callerSession.session
    invitation.stateChange.addListener((newState: SessionState) => {

      switch (newState) {

        case SessionState.Initial:
          console.log('Initial');
          break;

        case SessionState.Establishing:
          console.log('Session is establishing.')
          // tis.ringAudio.pause();
          break;

        case SessionState.Established:

        
          tis.callcenterSharedService.handleMic(true);
          tis.callAcceptTone()
          tis.callcenterSharedService.stopRing()
          tis.callcenterSharedService._agentStatus.next({ status: StatusValueEnum.ON_CALL, updateStatus: true })
          tis.remoteAudio.srcObject = invitation.sessionDescriptionHandler['remoteMediaStream'];
          tis.remoteAudio.play();
          if (!invitation.elapsedTime) {
            invitation.elapsedTime = 0
          }
          tis.callcenterSharedService.startCallTimer(callerSession)

          if(callerSession.customValues.isMuted)
            {
              tis.callcenterSharedService.muteCall(callerSession)
            }
            callerSession.customValues.notification_established  = true
            callerSession.customValues.call_answered = true
            if(callerSession.customValues.callConnectTime == null)
              callerSession.customValues.callConnectTime  = new Date().getTime();
            let local_session = _.merge({}, callerSession)
            local_session.session = {};
            local_session.session["state"] = "Established";
            this.callcenterSharedService.updateCallInfoInLocalStorage(local_session)
          if(!this.callcenterSharedService.lastDialledNumber?.length) 
            {
              this.callcenterSharedService.showDialer = false
            } 
          if(callerSession.callDetails?.tag)
            {
              this.callNotifyService.closeNotification(callerSession.callDetails.tag)
            }
        
        break;

        case SessionState.Terminated:
          console.log('Call Ended');
          tis.callcenterSharedService.disconnectMic()
          if (!tis.isOnHold(callerSession)) {//only enters if not on hold
            if (tis.sipDetails.status == 'On Call') {
              tis.updateLastCallTime(invitation);
            }
            tis.callcenterSharedService.clearCompletedCallsfromCallQ();
            tis.callDeclineTone();

            if (tis.initialCallQ?.length > 0) {
              tis.callcenterSharedService.ring();
            }
            else {
              tis.callcenterSharedService.stopRing();
            }
            if (!invitation.customValues?.isCallOnHold) {
              tis.callcenterSharedService.stopCallTimer(callerSession)
            }
            this.callcenterSharedService.stopCheckoutTimer(callerSession)
            this.callcenterSharedService._agentStatus.next({ status: StatusValueEnum.OFFLINE, updateStatus: false })

            if(callerSession.callDetails?.tag)
              {
                this.callNotifyService.closeNotification(callerSession.callDetails.tag)
              }

          }
          break;
        default:
          break;
      }
    });

  }


  clearUnholdedCallFromCallQ(call_id) {
    
    const index = this.callQ.findIndex(x => x.callDetails.call_id == call_id && x.session.state == 'Terminated')
    if (index != -1) {
      var _callQ = this.callQ
      _callQ.splice(index, 1)
      const callQ = _callQ
      this.callcenterSharedService.callQ = callQ;
    }

  }

  private get initialCallQ() {
    return this.callcenterSharedService.initialCallQ
  }
  private get callQ() {
    return this.callcenterSharedService.callQ
  }

  private get acceptCallQ() {
    return this.callcenterSharedService.acceptCallQ
  }

  private get sipDetails() {
    return this.callcenterSharedService.sipDetails
  }
  private get activeCallerSession() {
    return this.callcenterSharedService.activeCallerSession
  }



  pushtoCallQ(callsession:any)
  {
    callsession.customValues = {
        'isCallOnHold': false, 'isMuted': false, 'callerName': '', 'callType': "inbound",'elapsedTime':0,
        'notification_established':false,'timeout':callsession.callDetails.timeout, 'checkoutTime' :0,
        'isAgentCall':false, 'callStartTime': new Date().getTime(), 'callConnectTime': null, 'call_answered': false
      }
      if(callsession.callDetails?.call_type)
        {
          if(callsession.callDetails?.call_type == "agent_to_agent_inbound")
            {

              callsession.customValues.isAgentCall = true
            }
        }
      const currentValue = this.callQ;
      //if duplicate exists then remove it from callQ and add active call to callQ
      const filteredValue = currentValue.filter(session => session.callDetails.from_number !== callsession.callDetails.from_number);
      const updatedValue = [...filteredValue, callsession];
      this.callcenterSharedService.callQ = updatedValue;

      console.log('pushcallq', this.callcenterSharedService.callQ )

      this.callcenterSharedService.checkCallTimeout(callsession)

  }

  async oncallConnect(callerSession)
  {
    const hasPermission = await this.callcenterSharedService.requestMicrophonePermission();
    if (!hasPermission) {
      // alert('Microphone access is required to make a call.');
      // this.removeCallFailed(callerSession)
      return;
    }
      callerSession.session.state = 'connect'
      this.callcenterSharedService.updateCallQ(callerSession,'inbound')
      
      // const isSipDisconnected: boolean = !this.callcenterSharedService.connection ||
      // this.callcenterSharedService.connection?.state != 'Started'

      console.warn('sip connection state on connect call',this.sipConnectionService.isConnected())

      if(!this.sipConnectionService.isConnected())
        {
              try {
                this.sipConnectionService.initialiseConnection().then((state) => {
            
                  if (state === RegistererState.Registered) {
                    
                        console.log('registered from connect call')
                        this.connectTocall(callerSession)  
                        
                    }
                  else
                  {
                    this.toastr.error('failed to connecting sip.')
                    this.removeCallFailed(callerSession) 
                  }
                })    
              }
              catch (e) {
                this.toastr.error('Failed to initialize sip connection');
                this.removeCallFailed(callerSession)
              }
        }
        else
        {
          this.connectTocall(callerSession)
        }
  }


  /**
   * Requesting to route call to this user
   * @param callerSession 
   */
  connectTocall(callerSession,retryCount:number = 0)
  {
    this.inviteCall();

        setTimeout(() => {
          console.warn('call connetct try-'+retryCount)
      
          let payload = {
            call_sid: callerSession.callDetails.call_id,
            to_number: callerSession.callDetails.to_number,
            sip_username: 'sip:' + this.callcenterSharedService.sipDetails.sip_uri,
            call_center_enabled: true, 
            project_id: callerSession.callDetails.project_id,
            from_number:callerSession.customValues.isAgentCall == true?callerSession.callDetails.from_number:null,
            call_type  :callerSession.callDetails?.call_type,
            is_override: this.is_override,
            call_recording_enabled: callerSession.callDetails?.call_recording_enabled || false
          };
          this.callcenterSharedService.setAutoAcceptableCallIds(callerSession.callDetails.call_id)
          this.subs = this.callCenterService.connectToCall(payload).subscribe((response) => {
        
            
            if(response.http_status == 200)
              {
                this.is_override = false;
                console.log('callidinconnectcall', callerSession.callDetails.call_id)
                console.log('connec8tcallres',response)
              }
            else
              {
                if(retryCount<=2)
                  {
                      retryCount++
                      this.connectTocall(callerSession,retryCount)
                  }
                else
                {
                  this.toastr.error('Either the call is ended or '+
                    'someone else from your team is attended the call', 'Unable to connect to the call')
                 this.removeCallFailed(callerSession)
                }
       
              }
          },error=>{
            if(retryCount<=2)
              {
                
                  retryCount++
                  this.connectTocall(callerSession,retryCount)
              }
            else
              {
                this.toastr.error('Either the call is ended or '+
                  'someone else from your team is attended the call', 'Unable to connect to the call')
                this.removeCallFailed(callerSession)
              }
             
          })
          
        }, retryCount*1000);
  }

  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play();
  }

  callAcceptTone() {
    this.acceptTone.src = "../../../../assets/ringtone/pickup tone.mp3"
    this.acceptTone.play();
  
  }

  getIncomingCallDetailsApi(session: any) {

    let callId = session.customValues.call_id
    if (callId) {
      this.subs = this.callCenterService.getCallDetails(callId).subscribe(response => {

        if (response.http_status == 200) {

          this.iscallcenterEnabled = true;
          this.block_id = response.data.result.block_id ? response.data.result.block_id : null;
          if (session.customValues) {
            // session.customValues.callerName = response.data?.result?.contact_name || null;
            session.customValues.callDetails = response.data.result
            console.log('calldetails', session.customValues.callDetails)
          }
        } else {

          this.iscallcenterEnabled = false;
          this.getDetailsofNonCallCenterNumber(session);

        }
      });
    } 
  }

  getDetailsofNonCallCenterNumber(session: any) {

    let toNumber = session.request.headers['X-Dialed-Number'][0].raw || null;
    let fromNumber = session.remoteIdentity.uri.user;
    this.subs = this.callCenterService.getNonCallCenterDetails(toNumber, fromNumber).subscribe(resp => {
      if (session.customValues) {
        session.customValues.callerName = resp.data?.result?.contact_name || null;
        session.customValues.callDetails = resp
      }
    },
      (error) => {
        console.error(error);
        this.toastr.error("Error Occurred")
      });
  }

  isOnHold(session) {
    if (session.customValues.isCallOnHold) {
      return true;
    } else {
      return false;
    }
  }

  clearCallQ() //to clear all callq
  {
    this.callcenterSharedService.callQ = [];
  }

  updateLastCallTime(endedCall) {

    // const isCallPresent = this.acceptCallQ.includes(endedCall);
    // if (isCallPresent) {
    //   let date = new Date();
    //   let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    //   payload.call_end_time = this.datePipe.transform(date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZZZ')
    //   this.subs = this.callCenterService.updateLastCallTime(payload).subscribe(responds => { }
    //     , (err) => {
    //       console.log(err);
    //     });
    // }
  }

  get holdCallQ() {
    return this.acceptCallQ?.filter(session => session.customValues.isCallOnHold == true)
  }
  public holdCall(callerSession,retryCount = 0) {

    const call_Details = callerSession.callDetails
    const is_hold = callerSession.customValues.isCallOnHold;

    if(retryCount<=1)
      {
        if (is_hold) //onunhold
        {
          if (this.callcenterSharedService.activeCallerSession) {
    
            if (this.callcenterSharedService.activeCallerSession.callDetails.call_id != call_Details.call_id) {
    
              this.toastr.warning("Please hold current call")
              return
            }
    
          }
        }
        // const options = {
        //   sessionDescriptionHandlerModifiers: !callerSession.customValues.isCallOnHold ? [Web.holdModifier] : []
        // }
        if (callerSession.session.state == 'Established' && !is_hold) {
        //  callerSession.session.invite(options).then(() => {
          //  if (!callerSession.customValues.isCallOnHold) {//put on hold
              let payload = {
                call_sid: call_Details.call_id, //this.call_Details.call_id,
                sip:this.callcenterSharedService.sipDetails.sip_uri,
                to_number: call_Details.to_number,//this.call_Details.call_to
                project_id:call_Details.project_id,
                call_type  :callerSession.callDetails?.call_type,
                call_recording_enabled: callerSession.callDetails?.call_recording_enabled || false
              };
              this.callcenterSharedService.setAutoAcceptableCallIds(call_Details.call_id)
              this.subs = this.callCenterService.putCallOnHold(payload).subscribe(response => {
    
                if(response.http_status == 200)
                  {
                    callerSession.customValues.isCallOnHold = true;
                    let local_session = _.merge({}, callerSession)
                    local_session.session = {};
                    local_session.session["state"] = "Terminated";
                    this.callcenterSharedService.updateCallInfoInLocalStorage(local_session)
                  }
                else
                  {
                    retryCount++
                    if(retryCount<=1)
                      {
                        this.holdCall(callerSession,retryCount)
                        console.warn("hold call failed-"+ retryCount);
                      }
                    else
                      {
                        this.handleHoldFailed(callerSession,true)
                      }
                  }
              },
                (error) => {
                  retryCount++
                  if(retryCount<=1)
                    {
                      this.holdCall(callerSession,retryCount)
                      console.warn("hold call failed-"+ retryCount);
                    }
                  else
                    {
                      this.handleHoldFailed(callerSession,true)
                    }
                });
            //}
           // callerSession.customValues.isCallOnHold = re_value;
            // this.callcenterSharedService.setAutoAcceptableCallIds(callerSession.session.request.headers['X-Signalwire-Callsid'][0].raw)
          // })
          //   .catch((error) => {
          //     console.warn(`Failed to ${!callerSession.customValues.isCallOnHold ? 'hold' : 'unhold'} call. Error:`, error);
          //   }); 
        } else {//release from hold
          if (is_hold && callerSession.session.state == 'Terminated') {
       
            let payload = {
              call_sid: call_Details.call_id,
              to_number: this.iscallcenterEnabled ? call_Details.to_number : callerSession.session.request.headers['X-Dialed-Number'][0].raw,
              sip_username: 'sip:' + this.sipDetails.sip_uri,
              call_center_enabled: this.iscallcenterEnabled,
              project_id:call_Details.project_id,
              call_type  :call_Details?.call_type,
              call_recording_enabled: call_Details?.call_recording_enabled || false
            };
            // callerSession.customValues.isCallOnHold = true
            this.callcenterSharedService.setAutoAcceptableCallIds( call_Details.call_id)
            this.subs = this.callCenterService.releaseCallFromHold(payload).subscribe((response) => {
             // if (response.data?.result.call_ended) {
                if(response.http_status != 200)
                  {            
                    this.toastr.warning('Caller hung up');
                    this.callcenterSharedService.removeCurrentCallsfromCallQ(call_Details.call_id)
                    this.callcenterSharedService.updateCallInfoInLocalStorage(callerSession, true)
                    this.callcenterSharedService.checkQandCloseCallCenterPanel();
                  }
                else if(response.http_status == 200)
                  {
                    callerSession.customValues.isCallOnHold = false
                    let local_session = _.merge({}, callerSession)
                    local_session.session = {};
                    local_session.session["state"] = "Established";
                    this.callcenterSharedService.updateCallInfoInLocalStorage(local_session)
                  }
          
             // }
            },
              (error) => {
                console.log('error', error);
    
              });
          }
         /**
          * if call failed hold and showing as hold without terminate
          */
          else if(is_hold && callerSession.session.state == 'Established')
            {
              this.handleHoldFailed(callerSession,false)
              // callerSession.customValues.isCallOnHold = false
              // //this.callcenterSharedService.handleMic(true)
              // this.callcenterSharedService.muteCall(false)
              
            }
        }
      }
  }

  handleHoldFailed(callerSession:any,value:boolean)
  {
    callerSession.customValues.isCallOnHold = value
    this.callcenterSharedService.muteCallHold(callerSession,value)
  }

  pickkCall(session) {

    if (this.activeCallerSession) {
      this.toastr.warning("Please Hold Current Ongoing Call !")
      return
    }
    //if (this.checkOutboundCallIsActive() == false) {
    var options = {
      'mediaConstraints': {
        'audio': { deviceId: this.audioInputSource ? { exact: this.audioInputSource } : undefined }
        , 'video': { deviceId: this.videoInputSource ? { exact: this.videoInputSource } : undefined }
      }
    };
    if (session.state == 'Initial') {
      this.callcenterSharedService.stopRing();
      session.accept(options);
      //this.movetoAcceptedCalls(session)
      localStorage.setItem('isOnCall', 'true');
      //this.callCenterShared.activeCallerSession = session

    }
    //  }
  }

  rejectCall(callerSession: any) {

    this.callcenterSharedService.stopCheckoutTimer(callerSession)
    
    if(callerSession.session.state == "push")
      { 
        this.removeCallFailed(callerSession)
        if (this.initialCallQ?.length > 0) {
          this.callcenterSharedService.ring();
        }
        else {
          this.callcenterSharedService.stopRing();
        }
      }
    if (callerSession.session.state == 'Initial') {
      callerSession.session.reject().then(() => {
        this.callDeclineTone();
      }).catch(() => {
        this.toastr.error('Failed to reject call')
        this.removeCallFailed(callerSession)
        if (this.initialCallQ?.length > 0) {
          this.callcenterSharedService.ring();
        }
        else {
          this.callcenterSharedService.stopRing();
        }
      })
    }
    if(callerSession.callDetails.tag)
      {
        this.callNotifyService.closeNotification(callerSession.callDetails.tag)
      }

  }

  endCall(callerSession) {

    const call_Details = callerSession.callDetails

    if (callerSession.session.state == 'Established' && callerSession.customValues.isCallOnHold == false) {
      callerSession.session.bye().then(() => {
        this.callDeclineTone();
      }).catch(() => {
        this.toastr.error('Failed to end call')
        this.removeCallFailed(callerSession)
      })
    } 
     /**
      * if call failed hold and showing as hold without terminate
      */
    else if(callerSession.session.state == 'Established' && callerSession.customValues.isCallOnHold == true)
      {
        callerSession.session.bye().then(() => {
          this.callDeclineTone();
          this.removeCallFailed(callerSession);
        }).catch(() => {
          this.toastr.error('Failed to end call')
          this.removeCallFailed(callerSession)
        })
      }
    else {
      let payload = {
        call_sid: call_Details.call_id,//this.call_Details.call_id,
        to_number: call_Details.to_number,
        username: null,
        project_id:call_Details.project_id,
        call_type  :call_Details?.call_type,
        call_recording_enabled: call_Details?.call_recording_enabled || false
      };
      this.subs = this.callCenterService.endCallFromHold(payload).subscribe(response => {
        if(response.http_status == 200)
          {
            callerSession.customValues.isCallOnHold = false;
            this.callcenterSharedService.clearCompletedCallsfromCallQ(false);
          }
        else
          {
            this.toastr.error("Failed to end call,"+response.message[0]);
            this.removeCallFailed(callerSession)
          }
      },
        (error) => {
          this.toastr.error("Failed to end call");
          this.removeCallFailed(callerSession)
        });
    }
  
  }

  removeCallFailed(callerSession)
  {
    this.callDeclineTone()
    this.callcenterSharedService.removeFromCallQ(callerSession.callDetails.call_id)
  }

  destroy() {
    this.subs.unsubscribe();
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterResponse, GFilterParam, GSortParam, TbFilterMetaModel } from 'app/core/models/grid-filter.models';
import { map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { IndexDbHttpService } from './index-db-http.service';
import { BaseService } from 'app/core/services/base.service';
import { SubSink } from 'subsink';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { localeData } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SfHttpService extends BaseService {
  
  private subs: SubSink = new SubSink();

  private storeMetaTBName = "store_metadata"

  private storeLimit = 50;

  constructor(private http: HttpClient, private indexDBService: IndexDbHttpService,
    private dbService: NgxIndexedDBService
  ) {
    super()
  }

  get<T>(
    apiurl: string,
    storename: string = null,
    storeDataSubject: Subject<any> = null,
    id = null,
    filterLimit: number = 0,
    filterOffset: number = null,
    params: HttpParams = null,
    _storeLimit: number = 0
  ): Observable<DataFilterResponse<T>> {

    if (_storeLimit > 0) {
      this.storeLimit = _storeLimit;
    }

    if (params) {
      apiurl = `${apiurl}?${params.toString()}`;
      const hasLimit = params.has('limit');
      const hasOffset = params.has('offset');
      if (hasLimit) {
        filterLimit = Number(params.get('limit'));
      }
      if (hasOffset) {
        filterOffset = Number(params.get('offset'));
      }
    }

    const apiCall$ = this.http.get<DataFilterResponse<T[]>>(apiurl, { observe: 'response' });

    this.indexDBService.checkStoreExists(storename).then((storeExists) => {
      if (storeExists) {
        if (id)
          this.indexDBService.getByKey(storename, id).subscribe(data => {
            if (data) {
              storeDataSubject.next({ data: { result: data } });
              console.log('locdata', data);
            }
            this.subs.unsubscribe();
          })
      }
      else {
        console.log(`Store '${storename}' does not exist.`);
      }
    },
      error => {
        console.error('Error retrieving filtered data from local DB:', error);
      });

    return apiCall$.pipe(
      switchMap(response => {
        if (response.body?.http_status == 200) {
          const apiData = response.body?.data.result || [];
          storeDataSubject.next({ data: { result: apiData } });

          this.indexDBService.checkStoreExists(storename).then((exists) => {
            if (exists) {
              if (id) {
                this.indexDBService.getByKey(storename, id).subscribe(locata => {
                  this.subs.unsubscribe();
                  this.indexDBService.updateRecord(storename, apiData).then((res) => {
                  })
                },
                  error => {
                    console.error('Error retrieving filtered data from local DB:', error);
                  });
              }
            } else {
              this.indexDBService.createObjectStore(storename).then((res) => {
                if (id) {
                  this.indexDBService.add(storename, apiData).subscribe((res) => {
                  });
                }
              });
            }


          });
          return of({
            message: response.body?.message || [],
            data: {
              result: apiData as T[],
              count: apiData.length,
              total_count: response.body?.data.total_count || 0
            },
            http_status: response.body?.http_status || 200
          } as DataFilterResponse<T>);
        }
      })
    );
  }



  post<T>(apiurl: string, option: any = {}, storename: string = null, filterLimit: number = 0,
    filterOffset: number = null, storeDataSubject: Subject<any> = null, params: HttpParams,
    _storeLimit: number = 0, locDbFilter: any = null): Observable<DataFilterResponse<T>> {

    if (_storeLimit > 0) {
      this.storeLimit = _storeLimit
    }
    if (params) {
      apiurl = `${apiurl}?${params.toString()}`
      const hasLimit = params.has('limit');
      const hasOffset = params.has('offset');
      if (hasLimit) {
        filterLimit = Number(params.get('limit'));
      }
      if (hasOffset) {
        filterOffset = Number(params.get('offset'));
      }
    }
    const apiCall$ = this.http.post<DataFilterResponse<T[]>>(apiurl, option, { observe: 'response' });

    this.indexDBService.checkStoreExists(storename).then((storeexists) => {
      if (storeexists) {

        this.indexDBService.getFilteredDataFromLocalDB(storename, option, filterOffset, filterLimit, locDbFilter).subscribe(locata => {
          this.indexDBService.checkStoreExists(this.storeMetaTBName).then((meta_storeExists) => {
            if (meta_storeExists) {
              this.indexDBService.getByKey<TbFilterMetaModel>(this.storeMetaTBName, storename).subscribe(meta_data => {
                if (locata.length > 0) {
                  storeDataSubject.next({ data: { result: locata, total_count: meta_data ? meta_data.totalCount : 0, count: locata.length || 0 } });
                  console.log('locdata first', locata)
                }
                this.subs.unsubscribe();
              }, error => {
                console.error('Error retrieving datafrom metatable:', error);
              });
            }
            else {
              console.log(`Store '${storename}' does not exist.`);
            }
          })
        },
          error => {
            console.error('Error retrieving filtered data from local DB:', error);
          });
      } else {
        console.log(`Store '${storename}' does not exist.`);
       }
    })


    return apiCall$.pipe(
      switchMap(response => {

        if (response.body?.http_status == 200) {
          const apiData = response.body?.data.result || [];
          storeDataSubject.next({ data: { result: apiData, total_count: response.body?.data.total_count || 0, count: apiData.length || 0 } });

          const metaData: TbFilterMetaModel = new TbFilterMetaModel();
          metaData.storeName = storename;
          metaData.totalCount = response.body?.data.total_count || 0;
          metaData.lastUpdate = new Date()
          this.indexDBService.checkStoreExists(this.storeMetaTBName).then((storeExists) => {
            if (!storeExists) {
              // Store doesn't exist, create it
              return this.indexDBService.createObjectStore(this.storeMetaTBName, 'storeName', false);
            } else {
              return Promise.resolve(); // Store exists, no need to create
            }
          }).then(() => {
            this.indexDBService.updateRecord(this.storeMetaTBName, metaData).then((res) => {
              console.log('Update successful, key:', res)
              this.indexDBService.checkStoreExists(storename).then((exists) => {
                if (exists) {
                 this.indexDBService.getFilteredDataFromLocalDB(storename, option, filterOffset, filterLimit, locDbFilter).subscribe(locata => {
                   this.subs.unsubscribe();
                    this.syncAPIDataWithIndexedDB(storename, locata, apiData);
                 },
                    error => {
                      console.error('Error retrieving filtered data from local DB:', error);
                    });
                }
                else {
                  this.indexDBService.createObjectStore(storename).then((res) => {
                    var data = [...apiData]
                    if(apiData?.length > this.storeLimit)
                    {
                       data = data.slice(0,this.storeLimit)
                    }

                    this.indexDBService.bulkAdd(storename, data).subscribe((res) => {
                    });
                    
                  })

                }
              })
            }).catch((error) => {
              console.error('An error occurred:', error);
            });
          }).catch((error) => {
            console.error('An error occurred:', error);
          });
          return of({
            message: response.body?.message || [],
            data: {
              result: apiData as T[],
              count: apiData.length,
              total_count: response.body?.data.total_count || 0
            },
            http_status: response.body?.http_status || 200
          } as DataFilterResponse<T>);
        }

      })
    );
  }
  syncAPIDataWithIndexedDB(storeName: string, LocDBData: any[], apiData: any[]): Promise<boolean> {

    return new Promise((resolve, reject) => {

     // this.indexDBService.getCountOfRecords(storeName).subscribe({
       // next: (count) => {

          let listIds: Set<number> | null = null;

          if (apiData.every(item => 'id' in item)) {
            // If every item has an 'id' property, create the Set of IDs
            listIds = new Set(apiData.map(item => item.id));
          } else {
            // If any item lacks an 'id' property, set listIds to null
            listIds = null;
          }
          let update: boolean = false;
          let operations: Promise<void>[] = [];

          // Update or add items from the list to the DB
          console.log('listIds', listIds)
          if (listIds) {
            apiData.forEach(item => {

              let dbItem: any
              dbItem = LocDBData.find(dbItem => dbItem.id === item.id);
              if (dbItem) {
                // Update if different
                if (JSON.stringify(dbItem) !== JSON.stringify(item)) {
                  operations.push(
                    this.indexDBService.updateRecord(storeName, item).then(() => {
                      console.log(`Updated item with id: ${item.id}`);
                      update = true;
                    })
                  );
                }
              } else {
                // Add if not present in DB
                this.indexDBService.getCountOfRecords(storeName).subscribe({
                  next: (count) => {

                    if (count < this.storeLimit) {
                      operations.push(
                        this.indexDBService.add(storeName, item).toPromise().then(() => {
                          console.log(`Added new item with id: ${item.id}`);
                          update = true;
                        })
                      );
                    }
                  }
                  })
              }
              // Delete items from the DB that are not in the list
              if (LocDBData.length > 0) {
                LocDBData.forEach(dbItem => {
                  if (!listIds.has(dbItem.id)) {
                    operations.push(
                      this.indexDBService.delete(storeName, dbItem.id).toPromise().then(() => {
                        console.log(`Deleted from ${storeName} item with id: ${dbItem.id}`);
                        update = true;
                      })
                    );
                  }
                });
              }

            });
          }
          else {
            this.syncListswithoutID(apiData, LocDBData, storeName);
          }

          // Wait for all operations to complete
          Promise.all(operations).then(() => {
            console.log('newupdate', update);
            resolve(true);
          }).catch(error => {
            reject('Failed to sync data: ' + error);
          });
        // },
        // error: (error) => {
        //   reject('Failed to get count of records: ' + error);
        // }
   // });
    });
  }

  syncListswithoutID(apiData: any[], locData: any[], storeName) {

        const matchdataInLocDB = locData.filter(localItem =>
          apiData.some(apiItem => this.isMatch(apiItem, localItem,true))
        );
        const matchingIdList = matchdataInLocDB.map(item => item.id);
        console.log('matchingids', matchingIdList)
        const notmatchingItemsInLocDB = locData.filter(item => !matchingIdList.includes(item.id));

        console.log('notmatchingItemsInLocDB', notmatchingItemsInLocDB)
        const notMatchingIdList = notmatchingItemsInLocDB.map(item => item.id);

        if(notMatchingIdList.length > 0)
        {
          notMatchingIdList.forEach(id => {
            this.indexDBService.delete(storeName, id).subscribe(() => {
              console.log(`Deleted from ${storeName} item with id: ${notMatchingIdList}`);
            })
          })
       
        }
        const notIncludedItemsInApiData = apiData.filter(apiItem => {
          return !locData.some(localItem => this.isMatch(apiItem, localItem,true));
        });

        console.log('notIncludedItemsInAPI', notIncludedItemsInApiData)

        if(notIncludedItemsInApiData.length > 0)
        {   
          this.indexDBService.getCountOfRecords(storeName).subscribe({
               next: (count) => {
                console.log('count', count)
                console.log('storelimit', this.storeLimit)
                notIncludedItemsInApiData.forEach(item => {
                 if (count < this.storeLimit) {
                  this.indexDBService.add(storeName, item).subscribe(() => {
                    console.log(`Added new item with id: ${item.id}`);
                  })
                  count++;
                 }
                })
               }   
              }) 
        }
  }

  isMatch(obj1: any, obj2: any,removeLocdbidkey: boolean =false): boolean {
    
    const apiDataKeys = Object.keys(obj1);
    const localDataKeys = removeLocdbidkey ? Object.keys(obj2).filter(key => key !== 'id') : Object.keys(obj2); // Exclude 'id' from comparison
  
    if (apiDataKeys.length !== localDataKeys.length) {
      return false;
    }
  
    return apiDataKeys.every(key => {

      if (typeof obj1[key] === 'object' && obj1[key] !== null) {
        return this.isMatch(obj1[key], obj2[key],false); // check nested objects
      } else {
        return obj1[key] === obj2[key];
      }
    });
  }


  unSuscribe() {
    this.subs.unsubscribe();
  }

}

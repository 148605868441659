import { Directive, HostListener, Input } from '@angular/core';
import { CallCenterSharedService } from 'app/modules/ring2voice/services/call-center-shared.service';
import { SipOutboundFunctionService } from 'app/modules/ring2voice/services/sip-outbound-function.service';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[InitiateCall]',
  standalone: true
})
export class InitiateCallDirective {

/**
   * Directive to initiate a call.
   * 
   * When applied to an element, this directive listens for click events and triggers
   * the initiation of a call using the provided parameters: callType, fromNumber, toNumber, agentsipuri, and callto_agent_id.
   * 
   * Example usage:
   * <button InitiateCall
   *         [callType]="'contact'"
   *         [fromNumber]="callerPhoneNumber"
   *         [toNumber]="recipientPhoneNumber"
   *         [agentsipuri]="sipUri">
   *   Initiate Call
   * </button>
   */

  @Input({required:true}) callType: 'contact'|'sip';

  @Input() fromNumber: any;

  @Input({required:true}) toNumber: any;

  @Input({required:true}) agentsipuri: string;
  
  @Input() callto_agent_id?: string;

  constructor(private callCenterService: SipOutboundFunctionService,
    private callcenterShared: CallCenterSharedService,
    private toastr : ToastrService
  ) { }

  /**
   * @Input callType: The type of call to initiate (e.g., 'contact' or 'sip').
   * @Input fromNumber: The source of the call.
   * @Input toNumber: The destination of the call.
   * @Input agentsipuri: The SIP URI of the agent initiating the call.
   * @Input callto_agent_id (optional): The ID of the agent to call (if callType=='sip).
   */

  @HostListener('click')
  onClick() {
    if(this.callcenterShared.activeCallerSession){
      this.toastr.error("You are already in a call...")
      return;
    }  
    console.log('type:',this.callType, 'from:',this.fromNumber, 'to:',this.toNumber, 'sipuri:',this.agentsipuri, 'callto_agentid:',this.callto_agent_id);
    // Initiate the call using the Call Center Service
    this.callCenterService.makeCall(this.callType, this.callcenterShared.sipDetails.send_as, this.toNumber, this.agentsipuri, this.callto_agent_id);
    this.callcenterShared.popupOpenStatus.next(true)

  }

}

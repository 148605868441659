<div class="p-2">
    <form action="" [formGroup]="itemForm">

        <div class="flex justify-between items-center mb-2 mt-2">
            <button class="ml-auto text-gray-500 hover:text-gray-700" (click)="onCloseContact()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>

        <div class="flex justify-between items-center p-1 tracking-[-0.88px]">
            <div class="font-semibold text-[22px]">
                @if(itemForm.get('id').value)
                {
                Edit &nbsp; {{itemForm.get('first_name').value}}
                }
                @else
                {
                Add New Company User
                }

            </div>
            @if(itemForm.get('id').value)
            {
                <button matTooltip="Delete" class="w-5 h-5 flex items-center justify-center ml-2" (click)="delete()">
                    <img alt="call" src="../../../../../assets/icons/common-icons/trash-red-icon.svg">
                </button>
            }
        </div>
        <div class="flex items-center space-x-4 mt-3">
            <!-- <img src="../../../../../assets/icons/avatar-roundedgray.svg" alt="Avatar" class="w-[150px] h-[150px]"> -->
            <div class="rounded-full p-1">
                <div class="rounded-full w-[150px] h-[150px] overflow-hidden">
                    @if(thumbNail?.url){
                    <img [src]="thumbNail?.url" class="w-full h-full object-cover">
                    }
                    @else if(itemForm.get('image').value){
                    <img [src]="fileUrl + itemForm.get('image').value+ '.jpg'" class="w-full h-full object-cover">
                    }
                    @else{
                    <img src="../../../../../assets/icons/avatar-roundedgray.svg">
                    }
                </div>
            </div>
            <div class="tracking-[-0.32px] fade-in">
                <p class="text-base font-medium">Upload user photo (optional)</p>
                <p class="text-base text-gray-500">Only jpg ang png formats less than 2mb</p>
            </div>
            <div class="pl-2">
                <!-- <input #file_upload hidden="true" type="file" (change)="fupOnChange($event)" />
                <button _ngcontent-ng-c4133280373="" matripple="" class="mat-ripple border border-gray-400 shrink-0 whitespace-nowrap
            rounded-md max-h-10  flex items-center px-1 md:px-2 py-2 font-medium capitalize transition-colors 
            duration-300 transform hover:bg-neutral-10 focus:outline-nonefocus:ring-opacity-80"
                    fdprocessedid="xjezc9" (click)="file_upload.click()">
                    <img _ngcontent-ng-c4133280373="" src="../../../../../assets/icons/camera.svg" class="w-5"><span
                        _ngcontent-ng-c4133280373=""
                        class="mx-1 font-medium hidden text-base xl:block leading-[22px]">
                        @if(thumbNail?.url||itemForm.get('image').value){
                            Reset
                        }@else {
                            Upload
                        }
                    </span></button> -->
                <div class="flex justify-between">
                    <div class="flex flex-row justify-center items-center gap-2">
                        <button *ngIf="thumbNail?.url || itemForm.get('image').value" mat-stroked-button
                            [matTooltip]="'Remove logo'" type="button"
                            class="flex items-center px-[7px] py-2.5 rounded-[5px] border border-[#24223c33]"
                            (click)="removeProfileImg()">
                            <img alt="delete" src="../../../../../assets/icons/common-icons/trash.svg">
                        </button>
                        <input #file_upload hidden="true" ImageCropper type="file"
                            (onAfterCropped)="handleCroppedImage($event)" />
                        <button _ngcontent-ng-c4133280373="" matripple="" class="mat-ripple border border-gray-400 shrink-0 whitespace-nowrap
                        rounded-md max-h-10  flex items-center px-1 md:px-2 py-2 font-medium capitalize transition-colors 
                            duration-300 transform hover:bg-neutral-10 focus:outline-nonefocus:ring-opacity-80" fdprocessedid="xjezc9"
                            (click)="file_upload.click()">
                            <img _ngcontent-ng-c4133280373="" src="../../../../../assets/icons/camera.svg"
                                class="w-5"><span _ngcontent-ng-c4133280373=""
                                class="mx-1 font-medium hidden text-base xl:block leading-[22px]">
                                @if(thumbNail?.url||itemForm.get('image').value){
                                Reset
                                }@else {
                                Upload
                                }
                            </span></button>
                    </div>
                </div>
            </div>

        </div>

        <div class="mt-6 tracking-[-0.32px]">
            <h2 class="text-[18px] font-semibold mb-3">Personal Information</h2>
            <div class="grid grid-cols-2 gap-6">
                <div>
                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label class="text-[16px] font-medium tracking-[.32px]">First Name</mat-label>
                        <input matInput placeholder="Enter your first name" formControlName="first_name">
                        <mat-error *ngIf="itemForm.get('first_name').hasError('required')">First Name is
                            required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label class="text-[16px] font-medium tracking-[-0.32px]">
                            <div class="flex w-full justify-between">
                                <span>Phone</span>
                                <span *ngIf="!showAdditional_phone" class="font-normal text-[#1065B4] cursor-pointer"
                                    (click)="showAdditional_phone=true">
                                    + <span class="underline">Another</span></span>
                            </div>
                        </mat-label>
                        <input matInput placeholder="Enter your phone number" formControlName="phone">
                        @if (itemForm.get('phone').hasError('pattern')) {
                            <mat-error>
                                Please enter a valid phone number
                            </mat-error>
                        }@else if(itemForm.get('phone').hasError('maxlength')){
                            <mat-error>
                                Phone number cannot exceed 15 digits
                            </mat-error>
                        }@else if(itemForm.get('phone').hasError('minlength')){
                            <mat-error>
                                Phone number should be minimum of 10 digits
                            </mat-error>
                        }

                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-full" *ngIf="showAdditional_phone" [@fadeInOut]>
                        <mat-label class="text-[16px] font-medium tracking-[-0.32px]">
                            <div class="flex w-full justify-between">
                                <span>Secondary Phone</span>
                                <span class="font-normal text-[#1065B4] cursor-pointer"
                                    (click)="removeAdditionalField('mobile')">
                                    - <span class="underline">Remove</span></span>
                            </div>
                        </mat-label>
                        <input matInput placeholder="Enter your phone number" formControlName="mobile">
                        @if (itemForm.get('mobile').hasError('pattern')) {
                        <mat-error>
                            Please enter a valid phone number
                        </mat-error>
                        }@else if(itemForm.get('mobile').hasError('maxlength')){
                        <mat-error>
                            Phone number cannot exceed 15 digits
                        </mat-error>
                        }@else if(itemForm.get('mobile').hasError('minlength')){
                        <mat-error>
                            Phone number should be minimum of 10 digits
                        </mat-error>
                        }
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label class="text-[16px] font-medium tracking-[.32px]">Last Name</mat-label>
                        <input matInput placeholder="Enter your last name" formControlName="last_name">
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label class="text-[16px] font-medium tracking-[-0.32px]">
                            <div class="flex w-full justify-between">
                                <span>Email</span>
                                <span *ngIf="!showAdditional_mail" class="font-normal text-[#1065B4] cursor-pointer"
                                    (click)="showAdditional_mail=true">
                                    + <span class="underline">Another</span></span>
                            </div>
                        </mat-label>
                        <input matInput placeholder="Enter your email" formControlName="email">
                        <mat-error *ngIf="itemForm.controls['email'].hasError('email')">
                            Please enter a valid email.
                        </mat-error>

                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-full" *ngIf="showAdditional_mail" [@fadeInOut]>
                        <mat-label class="text-[16px] font-medium tracking-[-0.32px]">
                            <div class="flex w-full justify-between">
                                <span>Secondary Email</span>
                                <span class="font-normal text-[#1065B4] cursor-pointer"
                                    (click)="removeAdditionalField('secondary_email')">
                                    - <span class="underline">Remove</span></span>
                            </div>
                        </mat-label>
                        <input matInput placeholder="Enter your email" formControlName="secondary_email">
                        <mat-error
                            *ngIf="itemForm.controls['secondary_email'].hasError('secondary_email')">
                            Please enter a valid email.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="">
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label class="text-[16px] font-medium tracking-[.32px]">Address</mat-label>
                    <input matInput placeholder="Enter your address" formControlName="address">
                </mat-form-field>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-6">
            <div>
                <mat-form-field class="w-full">
                    <mat-label class="text-[16px] font-medium tracking-[-0.32px]">State</mat-label>
                    <mat-select formControlName="state">
                        <mat-option *ngFor="let state of states" [value]="state.name">
                            {{ state.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="w-full">
                    <mat-label class="text-[16px] font-medium tracking-[.32px]">Zip</mat-label>
                    <input matInput placeholder="Enter your zip" formControlName="zip_code">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label class="text-[16px] font-medium tracking-[.32px]">City</mat-label>
                    <input matInput placeholder="Enter your city" formControlName="city">
                </mat-form-field>

                <div class="w-full">
                    <mat-label class="text-[16px] font-medium tracking-[.32px]">Timezone</mat-label>
                    <timezone-select [value]="itemForm.get('time_zone').value" (onChange)="onTimezoneChange($event)"
                        class="timezone-select text-[16px]"></timezone-select>
                </div>
            </div>


        </div>
        <div class="mt-4">
            <h2 class="text-[18px] font-semibold mb-4">Work Information</h2>
            <div formGroupName="company_details">
                <div class="grid grid-cols-2 gap-6">
                    <div *ngIf="addNew_Company">
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label class="text-[16px] font-medium tracking-[-0.32px]">
                                <div class="flex w-full justify-between">
                                    <span>Company Name</span>
                                    <span class="font-normal text-[#1065B4] cursor-pointer"
                                        (click)="removeAdditionalField('company')">
                                        + <span class="underline">Add Existing</span></span>
                                </div>
                            </mat-label>
                            <input matInput placeholder="Enter company name" formControlName="name">
                        </mat-form-field>
                    </div>

                    <div class="w-full" *ngIf="!addNew_Company">
                        <mat-label class="text-[16px] font-medium tracking-[-0.32px]">
                            <div class="flex w-full justify-between">
                                <span>Company Name</span>
                                <span class="font-normal text-[#1065B4] cursor-pointer" (click)="onAddNewCompany()">
                                    + <span class="underline">Add Company</span></span>
                            </div>
                        </mat-label>
                        <customer-select (valueChange)="setCompany($event)" (clearItem)="resetCompanyDetails()"
                            [value]="itemForm.get('company_det').value"></customer-select>
                    </div>
                    <div>
                        <div [formGroup]="itemForm">
                            <mat-form-field appearance="fill" class="w-full">
                                <mat-label class="text-[16px] font-medium tracking-[.32px]">Designation</mat-label>
                                <input matInput placeholder="Enter your Designation" formControlName="designation">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="">
                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label class="text-[16px] font-medium tracking-[.32px]">Address</mat-label>
                        <input matInput placeholder="Enter company address" formControlName="address">
                    </mat-form-field>
                </div>
                <div class="grid grid-cols-2 gap-6">
                    <div>
                        <mat-form-field class="w-full">
                            <mat-label class="text-[16px] font-medium tracking-[-0.32px]">State</mat-label>
                            <mat-select formControlName="state">
                                <mat-option *ngFor="let state of states" [value]="state.name">
                                    {{ state.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label class="text-[16px] font-medium tracking-[.32px]">Zip</mat-label>
                            <input matInput placeholder="Enter zip" formControlName="zip_code">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label class="text-[16px] font-medium tracking-[.32px]">City</mat-label>
                            <input matInput placeholder="Enter city" formControlName="city">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label class="text-[16px] font-medium tracking-[.32px]">Phone</mat-label>
                            <input matInput placeholder="Enter phone" formControlName="phone_1">
                            @if (itemForm.get('company_details.phone_1').hasError('pattern')) {
                            <mat-error>
                                Please enter a valid phone number
                            </mat-error>
                            }@else if(itemForm.get('company_details.phone_1').hasError('maxlength')){
                            <mat-error>
                                Phone number cannot exceed 15 digits
                            </mat-error>
                            }@else if(itemForm.get('company_details.phone_1').hasError('minlength')){
                            <mat-error>
                                Phone number should be minimum of 10 digits
                            </mat-error>
                            }

                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label class="text-[16px] font-medium tracking-[.32px]">Website</mat-label>
                            <input matInput placeholder="Enter website URL" formControlName="website">
                            <mat-error *ngIf="itemForm.get('company_details.website').hasError('pattern') && submitted">
                                Please enter a valid URL.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="">
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label class="text-[16px] font-medium tracking-[.32px]">Additional Information</mat-label>
                    <textarea matInput placeholder="Enter additional information"
                        formControlName="additional_information" rows="3"></textarea>
                </mat-form-field>
            </div>
            <div class="flex items-center justify-center mb-2">
                <button mat-button type="button" class="text-[16px] font-medium tracking-[.32px] rounded-[5px]"
                    style="border: 1px solid rgba(119, 67, 219, 0.50);background: rgba(119, 67, 219, 0.03);box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);"
                    (click)="closeContactPanel()">
                    Cancel</button>
                <button mat-flat-button type="submit"
                    class="ml-3 text-[#fff] bg-[#7743DB] text-[16px] font-medium tracking-[.32px] rounded-[4px]"
                    style="box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);" (click)="onSubmit()">Save</button>
            </div>
        </div>
    </form>
</div>
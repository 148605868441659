import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType} from 'libphonenumber-js';

@Pipe({
  name: 'phoneNumber',
  standalone: true
})
export class PhoneNumberPipe implements PipeTransform {

  constructor() {}

  transform(value: any, ...args: any[]): any {
      if (!value) return "";
      value = value.split("@")[0].replace(/\D/g, '');
      return value?.length > 6 ? new AsYouType('US').input(value) : value;
      // return this.maskPipe.transform(value,'(000) 000-000000000');
  }

}

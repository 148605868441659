import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from 'app/core/services/common.service';
import { SubSink } from 'subsink';
import { AppEventType, CommonPanelEvents } from 'app/core/enums/common.enum';
import { CompanyUserAddComponent } from 'app/modules/ring2voice/components/company-user-add/company-user-add.component';
import { CompanyUser } from 'app/modules/tenant-customers/models/companies.models';

@Component({
  selector: 'common-right-panel',
  standalone: true,
  imports: [CommonModule,CompanyUserAddComponent],
  templateUrl: './common-right-panel.component.html',
  styleUrl: './common-right-panel.component.scss'
})
export class CommonRightPanelComponent {

  public opened: boolean

  public subs: SubSink = new SubSink();

  public type: string

  // public contactSaveTempData : CompanyUser = null;

  public id:any;


  constructor(private _commonService:CommonService){

  }

  ngOnInit(): void {

    this.subs.sink = this._commonService.on(AppEventType.CommonPanelEvent).subscribe(event => {

      this.id = null
      if(event.payload.EventName == CommonPanelEvents.OpenContact){
        this.opened = true
        this.type = 'contact' 
        if(event.payload.AdditionalInfo && event.payload.AdditionalInfo.id){
          this.id = event.payload.AdditionalInfo.id
        }

      }
      else if(event.payload.EventName == CommonPanelEvents.ClosePanel){
        this.opened = false
        this.type = ''
      }

  });


  }

  onDeleteContact(event){
    this._commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:{EventName:CommonPanelEvents.DeleteContact,AdditionalInfo:event}})
  }
}

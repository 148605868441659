import { Directive, ElementRef, Input, HostListener, Injector, ComponentRef } from '@angular/core';
import { ContactInfoCardComponent } from '../components/contact-info-card/contact-info-card.component';
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
@Directive({
  selector: '[appTooltip]',
  standalone: true
})
export class ToolTipDirective {

  @Input('appTooltip') tooltipData: { name: string, image: string, email: string, number: string };
  @Input() showMoreInfo: boolean = true;

  private tooltipComponentRef: ComponentRef<ContactInfoCardComponent>;
  private isInsideTooltip = false;
  private showTooltipTimeout;

  private overlayRef: OverlayRef;

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
    private overlay: Overlay,
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltipTimeout = setTimeout(() => {
      if (!this.tooltipComponentRef) {
        this.showTooltip(this.tooltipData, this.elementRef.nativeElement);
      }
    }, 300);
  }

  @HostListener('mouseleave') onMouseLeave() {
    // this.hideTooltip();
    clearTimeout(this.showTooltipTimeout);
    setTimeout(() => {
      if (!this.isInsideTooltip) {
        this.hideTooltip();
      }
    }, 150);
  }

  private onTooltipAction(action: string) {
    console.log('Action:', action);
    if(action=='chat'){
      this.hideTooltip()
    }
    // Handle button action here
  }

  private onTooltipMouseEnter() {
    this.isInsideTooltip = true;
  }

  private onTooltipMouseLeave() {
    this.isInsideTooltip = false;
    this.hideTooltip();
  }

  showTooltip(data: any, origin: HTMLElement) {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    const positionStrategy: PositionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions([{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -10
      }, {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: 10
      }]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: false
    });

    const tooltipPortal = new ComponentPortal(ContactInfoCardComponent, null, this.injector);
    const tooltipRef = this.overlayRef.attach(tooltipPortal);

    tooltipRef.instance.mouseEnter.subscribe(() => this.onTooltipMouseEnter());
    tooltipRef.instance.mouseLeave.subscribe(() => this.onTooltipMouseLeave());
    tooltipRef.instance.action.subscribe((action) => this.onTooltipAction(action));

    tooltipRef.instance.data = data;
    tooltipRef.instance.showMoreInfo = this.showMoreInfo;
    // tooltipRef.instance.ngOnChanges(); // To ensure data is updated

    // Check if tooltip overflows viewport and adjust position
    this.adjustPosition(origin);
  }

  hideTooltip() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private adjustPosition(origin: HTMLElement) {
    const overlayRect = this.overlayRef.overlayElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const originRect = origin.getBoundingClientRect();

    let left = originRect.left + (originRect.width / 2) - (overlayRect.width / 2);
    let top = originRect.top - overlayRect.height - 10; // Default position

    // Adjust horizontally
    if (left < 0) {
      left = 10; // Padding from left
    } else if (left + overlayRect.width > viewportWidth) {
      left = viewportWidth - overlayRect.width - 10; // Add padding from the right
    }

    // Adjust vertically
    if (top < 0) {
      top = originRect.bottom + 10; // Position below the element
    } else if (top + overlayRect.height > viewportHeight) {
      top = viewportHeight - overlayRect.height - 10; // Adjust to fit within the viewport
    }

    // Apply position adjustments
    this.overlayRef.overlayElement.style.left = `${left}px`;
    this.overlayRef.overlayElement.style.top = `${top}px`;
  }

}

<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-10 px-5 pb-0">
            <!-- Light version -->
            <img
                class="dark:hidden w-40"
                src="assets/images/logo/logo-main.svg"
                alt="Logo image">
            <!-- Dark version -->
            <img
                class="hidden dark:flex w-40"
                src="assets/images/logo/logo-main.svg"
                alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 bg-[#F4F5F6]">

    <!-- Header -->
    <div  class="sticky h-14 top-0 flex flex-0 items-center w-full px-4 md:px-6 z-999 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <mat-label class="text-[20px] hidden font-semibold tracking-[-0.32px] sm:block sm:max-lg:truncate md:text-[26px]">{{title}}</mat-label>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- outbound dialer -->
            <!-- <div class="flex justify-center">
                <img src="../../../../../assets/icons/call-center/dialer-nav.svg" class="cursor-pointer mt-1" alt="Mute" (click)="openCallCenter()">
            </div> -->

            <active-call-badge></active-call-badge>
            <!-- <languages></languages> -->

           <div class="h-10 pr-3">
                <mat-form-field 
                appearance="outline" *ngIf="suborgs && suborgs.length" 
                class="w-48 h-full mt--1 pt-0">
                    <!-- <mat-label>Select an option</mat-label> -->
                    <mat-select   name= "suborg" [(ngModel)]="suborgSelected" (selectionChange)="setSuborg($event)" >                  
                     <mat-option *ngFor="let suborg of suborgs" [value]="suborg">{{suborg.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
           </div>

   

            <div class="flex justify-center px-1 shrink-0">
                <a href="https://scribehow.com/page/SimplyFuse_Knowledge_Base__RPmxynTaQ5aD2zC3fkOuLw" target="_blank">
                    <img src="../../../../../assets/icons/questions.svg" class="cursor-pointer" alt="FAQ">
                </a>
            </div>



            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <notifications class=""></notifications> -->
            
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button> -->
            <user></user>

            <!-- agent section -->
            <!-- <agent-status></agent-status> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">SimplyFuse &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->

<!-- Call center panel -->
<div class="flex flex-col flex-auto">
    <call-center-panel class="z-99"></call-center-panel>
</div>
<div class="flex flex-col flex-auto">
    <common-right-panel></common-right-panel>
</div>
  
